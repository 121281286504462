import { ButtonSubmit } from "../Components/Button";
import { InputLong, InputSmall, Textarea } from "../Components/Input";
import Layout from "../Components/Layout/Layout";
import { FaMapLocationDot } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";

const ContactUs = () => {
  return (
    <Layout>
      <main className="w-full h-full pl-3 py-5 md:pl-20 md:py-14">
        <div className="w-full flex gap-3 justify-between">
          <div className="w-8/12 flex flex-col lg:flex-row">
            <div className="w-full lg:w-7/12">
              <h1 className="text-blackTwo font-lexend text-4xl md:text-5xl font-normal">
                Let us make impact... <br />
                Together!
              </h1>
              <div className="w-full md:w-9/12">
                <div className="w-full flex justify-between font-manrope mt-5">
                  <InputSmall
                    label="Your First Name"
                    type="text"
                    placeholder="First Name"
                    htmlFor="firstName"
                  />
                  <InputSmall
                    label="Your Last Name"
                    type="text"
                    placeholder="Last Name"
                    htmlFor="lastName"
                  />
                </div>
                <InputLong
                  label="Your Email"
                  type="Email"
                  placeholder="Email"
                  htmlFor="email"
                />
                <Textarea
                  label="Your Message"
                  placeholder="message"
                  row="5"
                  id="textarea"
                  name="textarea"
                  htmlFor="textarea"
                />
              </div>
              <ButtonSubmit type="submit" name="Submit" className="w-[200px]" />
            </div>
            <div className="w-4/12 mt-44 font-manrope text-blackTwo text-xl">
              <div className="w-full flex  justify-between">
                <FaMapLocationDot className="text-pink" />
                <p className="w-10/12 text-left text-base 2xl::text-xl">
                  Yaba, Lagos
                </p>
              </div>
              <div className="mt-8 w-full flex justify-between">
                <FiPhoneCall className="text-pink" />
                <p className="w-10/12 text-left text-base 2xl::text-xl">
                  +234 902 261 8668
                </p>
              </div>
              <div className="mt-8 w-full flex justify-between">
                <MdOutlineEmail className="text-pink" />
                <p className="w-10/12 text-left text-base 2xl::text-xl">
                  business@heaveventures.com.ng
                </p>
              </div>
            </div>
          </div>
          <div className="w-4/12 h-40 md:h-[459px]">
            <img
              src="Images/contactUs.jpg"
              alt="contact"
              className="w-full h-full rounded-l-xl"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ContactUs;
