import React from 'react'
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from 'flowbite-react';
import { ParagraphBlackBase, ParagraphBlackBig } from '../Paragraph';

const InnovationAccordion = () => {
    return (
        <Accordion collapseAll className='border-0 border-t border-b border-r-0'>
            <AccordionPanel>
                <AccordionTitle className=' focus:ring-0 focus:border-transparent'>
                    <ParagraphBlackBig content={'Autonomous Idea generator'} />
                </AccordionTitle>
                <AccordionContent>
                    <ParagraphBlackBase content={'Leverage artificial intelligence to autonomously generate novel concepts, solutions, or innovations without human intervention. Our solution uses algorithms, data analysis, and machine learning to generate ideas across various domains, serving as a creative tool or resource for problem-solving and innovation.'} />
                </AccordionContent>
            </AccordionPanel>
            <AccordionPanel>
                <AccordionTitle className=' focus:ring-0 focus:border-transparent'>
                    <ParagraphBlackBig content={'Autonomous Venture Sourcing'} />
                </AccordionTitle>
                <AccordionContent>
                    <ParagraphBlackBase content={'Use automated systems, algorithms, and data analytics to identify and evaluate potential investment opportunities without direct human intervention. It relies on technology to autonomously source, vet, and prioritize ventures based on predefined criteria, optimizing efficiency and enabling proactive decision-making in investment strategies.'} />
                </AccordionContent>
            </AccordionPanel>
            <AccordionPanel>
                <AccordionTitle className=' focus:ring-0 focus:border-transparent'>
                    <ParagraphBlackBig content={'Autonomous Trends/Market scout'} />
                </AccordionTitle>
                <AccordionContent>
                    <ParagraphBlackBase content={'Involves researching and analyzing emerging technologies, consumer behaviours, and market dynamics to identify opportunities and risks in whatever industry or sector you operate in. It focuses on staying ahead of developments in fields like robotics and AI to inform strategic decision-making and innovation.'} />
                </AccordionContent>
            </AccordionPanel>
            <AccordionPanel>
                <AccordionTitle className=' focus:ring-0 focus:border-transparent'>
                    <ParagraphBlackBig content={'AI no-code toolbox '} />
                </AccordionTitle>
                <AccordionContent>
                    <ParagraphBlackBase content={'AI-based no-code list that provides user-friendly platforms to enable users without coding expertise to build, iterate and learn fast. Essentially experiment fast!'} />
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
    )
}

export default InnovationAccordion