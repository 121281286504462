import React from "react";

export const ParagraphSmallGray = ({ content }) => {
  return (
    <p className="text-sm font-manrope font-medium not-italic text-gray leading-6 m-2">
      {content}
    </p>
  );
};

export const ParagraphSmallBlack = ({ content }) => {
  return (
    <p className="text-sm font-manrope font-medium not-italic text-blackTwo leading-6 m-2">
      {content}
    </p>
  );
};
export const ParagraphSmallBlackLight = ({ content }) => {
  return (
    <p className="text-sm font-manrope font-thin italic text-gray leading-6 m-2">
      {content}
    </p>
  );
};
export const ParagraphSmallBlack2 = ({ content }) => {
  return (
    <p className="text-sm font-manrope font-medium not-italic text-blackTwo leading-6">
      {content}
    </p>
  );
};
export const ParagraphBlackBase = ({ content }) => {
  return (
    <p className="text-base font-manrope font-thin not-italic text-blackTwo leading-6 m-2">
      {content}
    </p>
  );
};

export const ParagraphExtraLarge = ({ content }) => {
    return (
        <p className='text-4xl md:text-5xl font-lexend font-thin not-italic text-black leading-12 m-2'>{content}</p>
    )
}
export const ParagraphExtraLargeBold = ({ content }) => {
    return (
        <p className='text-5xl font-lexend font-bold not-italic text-black leading-12 m-2'>{content}</p>
    )
}
export const ParagraphExtraLargeWhite = ({ content }) => {
    return (
        <p className='text-3xl lg:text-5xl font-lexend font-bold not-italic text-white leading-9 lg:leading-15 m-2'>{content}</p>
    )
}

export const Paragraph = ({ content }) => {
  return (
    <p className="text-xl font-manrope font-medium not-italic text-gray leading-8 my-5">
      {content}
    </p>
  );
};

export const ParagraphBlack = ({ content }) => {
    return (
        <p className='text-xl font-manrope font-medium not-italic text-blackTwo leading-8 my-5'>{content}</p>
    )
}
export const ParagraphBlackThin = ({ content }) => {
    return (
        <p className='text-xl font-manrope font-thin not-italic text-blackTwo leading-8 my-5'>{content}</p>
    )
}

export const ParagraphWhite = ({ content }) => {
    return (
        <p className='text-xl font-manrope font-medium not-italic text-white leading-8 my-5'>{content}</p>
    )
}

export const ParagraphBlackBig = ({ content }) => {
  return (
    <p className="text-1xl md:text-3xl font-lexend font-medium not-italic text-blackTwo leading-8 my-3">
      {content}
    </p>
  );
};
export const ParagraphBlackBigLight= ({ content }) => {
  return (
    <p className="text-3xl font-lexend font-thin not-italic text-blackTwo leading-8 my-3">
      {content}
    </p>
  );
};
export const ParagraphBlackBigLead = ({ content }) => {
  return (
    <p className="text-2xl md:text-3xl font-lexend font-medium not-italic text-blackTwo leading-10 my-3">
      {content}
    </p>
  );
};

export const ParagraphWhiteBig = ({ content }) => {
  return (
    <p className="text-3xl font-lexend font-medium not-italic text-white leading-8 my-3">
      {content}
    </p>
  );
};

export const ParagraphWhite2xl = ({ content }) => {
  return (
    <p className="text-2xl font-lexend font-medium not-italic text-white leading-6 my-3">
      {content}
    </p>
  );
};

export const ParagraphMediumLargeBlack = ({ content }) => {
  return (
    <p className=" text-3xl md:text-4xl font-lexend font-normal not-italic text-blackTwo leading-10 md:leading-15 ">
      {content}
    </p>
  );
};

export const ParagraphExtraXLargeBlack = ({ content }) => {
  return (
    <p className="text-5xl md:text-6xl font-lexend font-medium not-italic text-blackTwo leading-10 my-1 md:my-5 md:leading-17">
      {content}
    </p>
  );
};

export const ParagraphBlackBlog = ({ content }) => {
    return (
      <p className="text-xl font-manrope font-medium not-italic text-blackTwo leading-8 my-2">
        {content}
      </p>
    );
  };
