import React from "react";

export const ButtonPrimary = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`text-white bg-deepred w-full h-10  justify-center px-4 py-2 text-base font-normal shadow-sm ring-deepred border-transparent rounded-full ring-2 font-manrope border hover:bg-secondary`}
    >
      {name}
    </button>
  );
};
export const ButtonPrimaryOrange = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`text-white bg-OrangeSecond w-full h-10  justify-center px-3 py-2 text-base font-normal shadow-sm ring-OrangeSecond border-transparent rounded-full ring-2 font-manrope border hover:bg-OrangeSecond`}
    >
      {name}
    </button>
  );
};

export const ButtonTrans = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={` text-blackFour bg-white w-full h-10  justify-center px-4 py-2 text-base font-normal shadow-sm ring-blackFour border-transparent rounded-full ring-1 font-manrope border hover:bg-white hover:text-grayThree`}
    >
      {name}
    </button>
  );
};
export const ButtonTransBgWhite = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={` text-blackFour bg-white w-full h-10  justify-center px-4 py-2 text-base font-normal shadow-sm ring-white border-transparent rounded-full ring-1 font-manrope border hover:bg-white hover:text-grayThree`}
    >
      {name}
    </button>
  );
};

export const ButtonSubmit = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={` text-white text-2xl bg-deepred cursor-pointer w-52 h-14 mt-10  justify-center px-4 py-2  font-normal shadow-sm ring-deepred border-transparent rounded-full ring-1 font-manrope border hover:bg-darkred hover:text-white`}
    >
      {name}
    </button>
  );
};

export const ButtonWhiteBg = ({ type, onClick, name }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`w-full text-deepred text-sm flex justify-center items-center  bg-white cursor-pointer  h-8 px-2 mt-2   font-normal shadow-sm ring-deepred border-transparent rounded-full ring-1 font-manrope border outline-none`}
    >
      {name}
    </button>
  );
};
