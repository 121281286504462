import React from "react";

export const InputSmall = ({ label, type, placeholder, value, htmlFor }) => {
  return (
    <div className="w-5/12 flex flex-col">
      <label
        htmlFor={htmlFor}
        className="text-base leading-5 font-bold text-blackTwo"
      >
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className="h-11 border-[1px] border-gray rounded-md mt-2 px-4 text-base font-normal outline-none"
      />
    </div>
  );
};

export const InputLong = ({ label, type, placeholder, value, htmlFor }) => {
  return (
    <div className="w-full flex flex-col mt-5">
      <label
        htmlFor={htmlFor}
        className="text-base leading-5 font-bold text-blackTwo"
      >
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className="h-11 border-[1px] border-gray rounded-md mt-2 px-4 text-base font-normal outline-none"
      />
    </div>
  );
};

export const Textarea = ({
  label,
  placeholder,
  col,
  row,
  id,
  name,
  htmlFor,
}) => {
  return (
    <div className="w-full flex flex-col mt-5">
      <label
        htmlFor={htmlFor}
        className="text-base leading-5 font-bold text-blackTwo"
      >
        {label}
      </label>

      <textarea
        name={name}
        id={id}
        rows={row}
        placeholder={placeholder}
        className="resize-none border-[1px] border-gray rounded-md mt-2 px-2 py-2 text-base font-normal outline-non"
      ></textarea>
    </div>
  );
};
