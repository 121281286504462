import React from 'react'
import { Card } from 'flowbite-react';
import { ParagraphBlackBig, ParagraphSmallBlack2 } from '../Paragraph';

const AboutCard = ({title, descrption, image}) => {
    return (
        <Card className="max-w-sm ring-white border-white lg:h-full rounded-3xl">
            <ParagraphBlackBig content={title} />
            <ParagraphSmallBlack2 content={descrption} />
            <div className="rounded-lg">
                <img src={image} alt={image} className={`rounded-2xl ${ image === 'Images/inno.jpg' ? 'h-20 lg:h-80' : 'h-20 lg:h-full'}`} />
            </div>
        </Card>
    )
}

export default AboutCard