import React from 'react'
import Layout from '../Components/Layout/Layout'
import { Paragraph } from '../Components/Paragraph'
import { ButtonTrans } from '../Components/Button'

const Home = () => {
    return (
        <Layout>
            <main className=' lg:px-20 md:py-6'>
                <div className="flex flex-col p-6 md:flex-row gap-10">
                    <div className=" w-full md:w-6/12 lg:w-8/12 md:h-3/5">
                        <img src="Images/home.png" alt="Home" className='w-full h-full' />
                    </div>
                    <div className="w-full md:w-6/12 lg:w-4/12">
                        <p className='hidden md:block text-5xl text-blackTwo font-lexend leading-15 font-normal'>
                            Empowering
                            <span className='text-primary pt-3'> Entrepreneurs </span>
                            and <span className='text-primary'>Corporates</span> to Succeed in the age of  <span className='text-primary'>Disruption</span>.
                        </p>

                        <p className='block md:hidden text-2xl text-blackTwo font-lexend leading-8 font-normal'> Empowering <span className='text-primary'> Entrepreneurs </span>
                            and <span className='text-primary'>Corporates</span> to Succeed in the age of  <span className='text-primary'>Disruption</span>.
                        </p>
                        <Paragraph content={'We work with Innovative leaders to drive revenue, investments and growth.'} />
                        <div className=" w-1/2 mt-4 md:mt-14">

                            <ButtonTrans name={'Discover Heave'} />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Home