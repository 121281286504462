import React, { useState } from 'react'
import { IoIosArrowForward, IoIosArrowBack, IoIosPeople } from "react-icons/io";
import Layout from '../Components/Layout/Layout'
import { ParagraphBlackBase, ParagraphBlackBigLead, ParagraphBlackBigLight, ParagraphBlackThin, ParagraphExtraLargeBold, ParagraphSmallBlack, ParagraphSmallBlack2, ParagraphSmallBlackLight } from '../Components/Paragraph'

const Consulting = () => {
    const [service, setService] = useState('Venture building/sourcing')

    const handleServiceChange = (value) => {
        setService(value);
    }

    const renderServices = () => {
        switch (service) {
            case 'Venture building/sourcing':
                return (
                    <div className="flex flex-col md:flex-row justify-between gap-5 items-center mt-10 md:mt-0">
                        <div className="">
                            <img src="Images/consult.jpeg" alt="" className='rounded-3xl' />
                        </div>
                        <div className="">
                            <ParagraphBlackBigLead content={service} />
                            <ParagraphSmallBlack content={"Embark on a journey of entrepreneurial success with our venture building and sourcing services. From ideation to execution, we'll guide you through the process of launching and scaling innovative ventures that disrupt markets and drive growth."} />
                        </div>
                    </div>
                );
            case 'Research and Development':
                return (
                    <div className="flex flex-col md:flex-row justify-between gap-5 items-center mt-10 md:mt-0">
                        <div className="">
                            <img src="Images/consult.jpeg" alt="" className='rounded-3xl' />
                        </div>
                        <div className="">
                            <ParagraphBlackBigLead content={service} />
                            <ParagraphSmallBlack content={' Stay at the forefront of innovation with our research and development expertise. Our seasoned professionals will collaborate with your team to conduct cutting-edge research, prototype new ideas, and bring breakthrough innovations to life.'} />
                        </div>
                    </div>
                );
            case 'Innovation Strategy':
                return (
                    <div className="flex flex-col md:flex-row justify-between gap-5 items-center mt-10 md:mt-0">
                        <div className="">
                            <img src="Images/consult.jpeg" alt="" className='rounded-3xl' />
                        </div>
                        <div className="">
                            <ParagraphBlackBigLead content={service} />
                            <ParagraphSmallBlack content={'Craft a robust innovation strategy that aligns with your business objectives and fuels sustainable growth. Our consultants will work closely with you to develop a roadmap for innovation, leveraging insights and best practices to drive meaningful impact.'} />
                        </div>
                    </div>
                );
            case 'Training and Workshops':
                return (
                    <div className="flex flex-col md:flex-row justify-between gap-5 items-center mt-10 md:mt-0">
                        <div className="">
                            <img src="Images/consult.jpeg" alt="" className='rounded-3xl' />
                        </div>
                        <div className="">
                            <ParagraphBlackBigLead content={service} />
                            <ParagraphSmallBlack content={'Inspire creativity and foster a culture of innovation within your organization through our training and workshop programs. From design thinking to agile methodologies, we offer interactive sessions that equip your teams with the skills and mindset needed to innovate effectively.'} />
                        </div>
                    </div>
                );
            case 'Talent and Technology':
                return (
                    <div className="flex flex-col md:flex-row justify-between gap-5 items-center mt-10 md:mt-0">
                        <div className="">
                            <img src="Images/consult.jpeg" alt="" className='rounded-3xl' />
                        </div>
                        <div className="">
                            <ParagraphBlackBigLead content={service} />
                            <ParagraphSmallBlack content={'Attract top talent and leverage the latest technologies to drive innovation excellence. Our experts will assist you in identifying and developing key talent, as well as implementing cutting-edge tools and platforms to accelerate innovation across your organization.'} />
                        </div>
                    </div>
                );

            default:
                return null;
        }
    }
    return (
        <Layout>
            <main>
                <div className="py-10 px-5 lg:px-10">
                    <div className="flex gap-2 px-2 md:px-10">
                        <ParagraphSmallBlack2 content={'Services'} />
                        <div className="mt-1"> <IoIosArrowForward /> </div>
                        <ParagraphSmallBlack2 content={service} />
                    </div>
                    <div className="flex px-2 md:px-10 mt-4 gap-10" >
                        <div className=" w-[30%] md:w-[20%]">
                            <ParagraphExtraLargeBold content={'Services'} />
                            <div className=" flex flex-col my-5">
                                <button className={`border-t border-b border-grayFive text-grayTwo p-4 md:p-6 ${service === 'Venture building/sourcing' ? 'text-primary' : 'text-grayTwo'}`} onClick={() => handleServiceChange('Venture building/sourcing')}>
                                    Venture Building/Sourcing
                                </button>
                                <button className={`border-t border-b border-grayFive text-grayTwo p-4 md:p-6 ${service === 'Research and Development' ? 'text-primary' : 'text-grayTwo'}`} onClick={() => handleServiceChange('Research and Development')}>
                                    Research and Development
                                </button>
                                <button className={`border-t border-b border-grayFive text-grayTwo p-4 md:p-6 ${service === 'Innovation Strategy' ? 'text-primary' : 'text-grayTwo'}`} onClick={() => handleServiceChange('Innovation Strategy')}>
                                    Innovation Strategy
                                </button>
                                <button className={`border-t border-b border-grayFive text-grayTwo p-4 md:p-6 ${service === 'Training and Workshops' ? 'text-primary' : 'text-grayTwo'}`} onClick={() => handleServiceChange('Training and Workshops')}>
                                    Training and Workshops
                                </button>
                                <button className={`border-t border-b border-grayFive text-grayTwo p-4 md:p-6 ${service === 'Talent and Technology' ? 'text-primary' : 'text-grayTwo'}`} onClick={() => handleServiceChange('Talent and Technology')}>
                                    Talent and Technology
                                </button>
                            </div>
                        </div>
                        <div className=" w-[55%] md:w-[75%] mt-10">
                        {renderServices()}
                        </div>
                    </div>
                </div>
                <div className="px-5 md:px-20 py-14">
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className="w-full lg:w-3/12">
                            <ParagraphExtraLargeBold content={'Case Studies'} />
                            <div className="md:h-80 md:w-80 my-5">
                                <img src="Images/consult.jpg" alt="" className='w-full h-full rounded-3xl' />
                            </div>
                        </div>
                        <div className="w-full lg:w-8/12">
                            <div className=" flex justify-end gap-4 mr-8">
                                <div className=" rounded-full border border-deepred h-10 w-10 p-3">
                                    <IoIosArrowBack className=' text-deepred' />
                                </div>
                                <div className="rounded-full border border-deepred h-10 w-10 p-3">
                                    <IoIosArrowForward className='text-deepred' />
                                </div>
                            </div>
                            <ParagraphBlackBigLight content={'Case Study 1: HealthHub Technologies in the Healthcare Industry'} />
                            <div className=" my-5">
                                <ParagraphSmallBlackLight content={'HealthHub Technologies, a  company in the healthcare sector, developed an innovative telemedicine platform that seamlessly connected healthcare providers with patients, enabling remote consultations, monitoring, and follow-up care.'} />
                            </div>
                            <div className=" my-5">
                                <ParagraphBlackBase content={" Process:"} />
                            </div>
                            <div className=" my-5">
                                <ParagraphBlackBase content={" Identifying Market Needs: Heave Ventures conducted extensive market research to identify gaps in healthcare accessibility and areas where technology could improve patient outcomes. The company recognized the growing need for remote healthcare solutions, particularly in regions with limited access to medical facilities."} />
                            </div>
                            <ParagraphBlackBase content={" Cross-Disciplinary Collaboration: Heave Ventures  in collaboration with HealthHub formed a multidisciplinary team comprising software developers, healthcare professionals, data scientists, and user experience designers. This collaborative approach ensured a comprehensive understanding of both technological requirements and healthcare nuances."} />

                        </div>

                    </div>

                </div>
                <div className="bg-OrangeLight py-10 px-3 md:py-20  md:px-10">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-0 md:py-20 px-10">
                        <div className="">
                            <ParagraphBlackBigLight content={'Contact us'} />
                            <div className="my-5">
                                <ParagraphBlackThin content={'e: business@heaveventures.com.ng'} />
                            </div>
                            <ParagraphBlackThin content={'+234 902 261 8668'} />
                        </div>
                        <div className="">
                            <IoIosPeople className='h-24 w-24' />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Consulting