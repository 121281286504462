import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ScrollTop from "./Components/ScrollTop";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Blog from "./Pages/Blog";
import Innovation from "./Pages/Innovation";
import Consulting from "./Pages/Consulting";

function App() {
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/innovation" element={<Innovation/>} />
        <Route path="/consulting" element={<Consulting/>} />
        <Route path="/contact" element={<ContactUs/>} />
        <Route path="/blog" element={<Blog/>} />

      </Routes>
    </>
  );
}

export default App;
