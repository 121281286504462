import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io";
import Layout from '../Components/Layout/Layout'
import { ParagraphBlack, ParagraphBlackBase, ParagraphBlackBig, ParagraphExtraLargeWhite, ParagraphExtraXLargeBlack } from '../Components/Paragraph'
import { ButtonPrimary, ButtonTransBgWhite } from '../Components/Button';
import AboutCard from '../Components/Cards/AboutCard';
import CallNo from '../Components/CallNo';

const About = () => {
    return (
        <Layout>
            <main className=''>
                <div className="flex flex-col md:flex-row px-5 md:px-20 py-5">
                    <div className="w-full md:w-3/12">
                        <p className='text-5xl text-deepred font-normal text-center md:text-start leading-15 font-lexend'>About Us</p>
                    </div>
                    <div className="w-full md:w-9/12">
                        <div className="w-full h-3=4/6">
                            <img src="Images/about.jpg" alt="about" className='rounded-5xl h-full w-full' />
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="">
                                <ParagraphBlack content={'We work with individuals and organizations to apply problem-solving methodologies and technology innovation for growth. We provide advisory that range from facilitating ideation, design thinking, managing ideas backlog to product development cycles and ecosystem development.'} />
                                <Link className=' text-primary text-base leading-5 flex'>Get in touch <IoIosArrowForward className=' text-primary mt-1 h-4' />
                                </Link>
                            </div>
                            <div className="">
                                <ParagraphBlack content={'We have built out a unique autonomous-innovation platform that promotes corporate innovation. The platform supports deal sourcing, investments analysis, ideation, research, product development, events and community amongst others. '} />
                                <Link className=' text-primary text-base leading-5 flex'>Explore our toolbox <IoIosArrowForward className=' text-primary mt-1 h-4' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" bg-OrangeLight flex flex-col-reverse md:flex-row justify-around items-center py px-5 lg:py-20 lg:px-10 h-highlong lg:h-data">
                    <div className="w-1/2 relative flex justify-center md:mt-10">
                        <div className="absolute bottom-48 md:bottom-36">
                            <ParagraphBlackBig content={'Collaboration'} />
                        </div>
                        <div className="absolute md:-left-10 md:-top-9 -top-[6.8rem] -left-24">
                            <ParagraphBlackBig content={'Openness'} />
                        </div>
                        <div className=" bg-blackThree w-1 h-60 absolute  -top-48 md:-top-28"></div>
                        <div className="border-t-4 border-blackThree w-full md:w-2/4 absolute -top-20 md:top-0 "></div>
                        <div className="absolute md:-right-10 md:-top-8 -top-[6.7rem] -right-24">
                            <ParagraphBlackBig content={'Resilience'} />
                        </div>
                        <div className="absolute md:top-36 top-10">
                            <ParagraphBlackBig content={'Empathy'} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 ">
                        <ParagraphExtraXLargeBlack content={'Our CORE'} />
                        <div className="mt-1 md:mt-28">
                            <ParagraphBlack content={'We understand the Innovation Value Chain thoroughly. With over 30 years experience evolving with tech advancements, our CORE approach have been tested by experts that will transform the fortune of your business.'} />
                        </div>
                    </div>
                </div>
                <div className="md:px-20 md:py-5 px-5 ">
                    <div className="text-center">
                        <ParagraphBlack content={'We are trusted by'} />
                        <div className="flex justify-between">
                            <div className=" md:h-20 md:w-56 h-14 w-56">
                                <img src="Images/lgaI.png" alt="Innnovation" className='h-full w-full' />
                            </div>
                            {/* <div className="py-2 md:py-7">
                                <p className=' text-graySeven text-sm md:text-2xl leading-6 font-lexend'>BizBeat Insights</p>
                            </div> */}
                            <div className="h-8 w-40 md:h-20 md:w-36">
                                <img src="Images/lga.png" alt="Lagos" className='h-full w-full' />
                            </div>
                            {/* <div className=" py-2 md:py-7">
                                <p className=' text-graySeven text-sm md:text-2xl leading-6 font-lexend'>CommerceCurrent Pulse</p>
                            </div> */}
                            <div className="h-10 w-24 md:h-24 md:w-24">
                                <img src="Images/nbcc.jpeg" alt="Fcmb" className=' h-full w-full' />
                            </div>
                            <div className="h-10 w-24 md:h-24 md:w-24">
                                <img src="Images/fcmb.png" alt="Fcmb" className=' h-full w-full' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" bg-grayEigth rounded-t-3xl rounded-b-3xl  h-full md:h-long px-5 lg:px-20 py-10">
                    <div className="flex flex-col md:flex-row justify-between relative">
                        <div className="w-full md:w-equal">
                            <div className="w-full lg:w-1/3">
                                <ButtonTransBgWhite name={'Our HOW'} />
                            </div>
                            <ul className='my-10'>
                                <li className='my-5'>
                                    <ParagraphBlackBase content={`> We identify opportunities through exploration and experimentation.`} />
                                </li>
                                <li>
                                    <ParagraphBlackBase content={`> We build ideas pipeline from internal and external sources..`} />
                                </li>
                                <li className='my-5'>
                                    <ParagraphBlackBase content={`> We work to improve team processes, streamline operations, and generate products to advance company growth..`} />
                                </li>
                            </ul>
                            <div className="mt-10 lg:mt-28">
                                <AboutCard title={'Innovation Strategy'} descrption={'We work with leaders at different organizational levels to build company innovation strategies and capacity for innovation.'} image={'Images/inovation.jpg'} />
                            </div>
                        </div>
                        <div className="w-full md:w-equal">
                            <div className="mt-5 md:mt-0 md:h-think">
                                <AboutCard title={'Design thinking'} descrption={'Our design experts have strong background in design thinking methodologies and offer training services. Our design experts have proven results in delivering human-centered solutions.'} image={'Images/thinking.jpg'} />
                            </div>
                            <div className="mt-5 md:mt-8 md:h-tech">
                                <AboutCard title={'Talent & Tech'} descrption={'We offer best-in-class technology engineering services to back your innovation strategy and plans. Our team of developers, devops engineers and designers would bring your products to life!'} image={'Images/tech.jpg'} />
                            </div>
                        </div>
                        <div className="w-full md:w-equal mt-5 md:mt-0">
                            <div className="md:h-data">
                                <AboutCard title={'Data Innovation'} descrption={'Our data scientists and digital experience designers draw upon the strengths of big data to provide analytics and insights coupled with the detailed market research.'} image={'Images/inno.jpg'} />
                            </div>
                            <div className="mt-8">
                                <AboutCard title={'Programmes'} descrption={'From ecosystem events to entrepreneurship trainings or bootcamps. We can partner with you to reach tens of thousands to create impact.'} image={'Images/prog.jpg'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-RedSecond rounded-t-full md:rounded-t-10xl h-high mt-10 md:mt-80 mx-5">
                    <div className="flex flex-col lg:flex-row mt-5 lg:px-20 py-5">
                        <div className="my-14 mx-10 lg:mx-14 w-full lg:w-2/5">
                            <div className=" w-64 mb-6 lg:mb-20">
                                <ButtonTransBgWhite name={'INDUSTRY AGNOSTIC'} />
                            </div>
                            <ParagraphExtraLargeWhite content={'We work and make impact across different industries and sectors'} />
                            <div className=" w-36">
                                {/* <ButtonPrimary name={'Call No'} /> */}
                                <CallNo />
                            </div>
                        </div>
                        <div className="lg:my-20 mx-10">
                            <div className="h-48 w-full md:h-60 md:w-96 lg:h-think lg:w-abt">
                                <img src="Images/abt.jpeg" alt="" className='h-full w-full rounded-2xl' />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default About