import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa6";
import { ParagraphWhite, ParagraphWhite2xl, ParagraphWhiteBig } from '../Paragraph';
import { ButtonPrimary } from '../Button';

const Footer = () => {
    const location = useLocation();
    const [isHome, setHome] = useState(false);
    const { pathname } = location;

    useEffect(() => {
        if (pathname === '/') {
            setHome(true)
        } else {
            setHome(false)
        }
    },[pathname]);
    return (
        <>
            {
                isHome ? (
                    <div className=" bg-blackThree h-10 rounded-t-2xl flex p-2 justify-center">
                        <p className=' text-sm text-white'>business@heaveventures.com</p>
                    </div>
                ) : (
                    <div className=" bg-blackThree rounded-t-3xl mt-24 lg:px-20 px-5">
                        <div className="px-5 lg:px-20 py-5 flex flex-col lg:flex-row lg:gap-40">
                            <div className="">
                                <ParagraphWhiteBig content={'Get Innovation Updates'} />
                                <div className=" my-10 relative">
                                    <input type="text" name="" id="" className=' border-grayFour bg-grayFour text-white rounded-full ring-transparent ring-1 focus:border-grayFour focus:ring-transparent px-3 h-12 w-full' />
                                    <div className=" w-36 h-8 absolute right-1 top-1">
                                        <ButtonPrimary name={'Subscribe'} />
                                    </div>
                                </div>
                                <div className="">
                                    <FaLinkedin className=' text-grayFive h-8 w-8' />
                                </div>
                            </div>
                            <div className=" mt-2">
                                <ParagraphWhite2xl content={'Links'} />
                                <ParagraphWhite content={'AI-Toolbox'} />
                                <ParagraphWhite content={'Services'} />
                                <ParagraphWhite content={'Blog'} />
                            </div>
                            <div className=" mt-2">
                                <ParagraphWhite2xl content={'Contact'} />
                                <ParagraphWhite content={'Yaba, Lagos'} />
                                <ParagraphWhite content={'Nigeria'} />
                                <ParagraphWhite content={'+234 - 810-781- 3382'} />
                                <ParagraphWhite content={'business@heaveventures.com'} />
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}

export default Footer