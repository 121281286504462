import React, { useEffect, useState } from 'react'
import {  NavLink, useLocation } from 'react-router-dom'
import { Navbar, NavbarBrand, NavbarCollapse, NavbarToggle } from 'flowbite-react';
import Logo from '../Logo'
import { ButtonPrimary } from '../Button'
import CallNo from '../CallNo';

const NavbarOne = () => {
    const location = useLocation();
    const [isActive, setIsActive] = useState("/")
    const { pathname } = location;

    const handleOptionClick = (option) => {
        setIsActive(option);
    };
    useEffect(() => {
        handleOptionClick(pathname);
    });


    return (
        // <div className='flex justify-between items-center  w-full h-16 px-20 py-3'>
        //     <Link to={'/'} smooth={true} duration={500}onClick={() => handleOptionClick("/")} >
        //         <Logo />
        //     </Link>
        //     <ul className='flex justify-between gap-10'>
        //         <li>
        //             <NavLink to={'/'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/")}>Home</NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={'/about'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/about' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/about")}>About Us</NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={'/innovation'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/innovation' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/innovation")}>Innovation Toolbox</NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={'/consulting'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/consulting' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/consulting")}>Consulting</NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={'/blog'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/blog' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/blog")}>Blog</NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={'/contact'} className={`text-grayTwo font-normal text-base leading-5 ${isActive === '/contact' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/contact")}>Contact Us</NavLink>
        //         </li>

        //     </ul>
        //     <div className="">

        //     <ButtonPrimary name={'Call No'} />
        //     </div>
        // </div>
        <div className="px-3 lg:px-20">
            <Navbar fluid rounded>
                <NavbarBrand href="">
                    <Logo />
                </NavbarBrand>
                <div className="flex lg:order-2 gap-4">
                    {/* <ButtonPrimary name={'Call No'} /> */}
                    <CallNo />
                    <NavbarToggle />
                </div>
                <NavbarCollapse>
                    <NavLink to={'/'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/")}>Home</NavLink>

                    <NavLink to={'/about'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/about' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/about")}>About Us</NavLink>

                    <NavLink to={'/innovation'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/innovation' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/innovation")}>Innovation Toolbox</NavLink>

                    <NavLink to={'/consulting'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/consulting' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/consulting")}>Consulting</NavLink>

                    <NavLink to={'/blog'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/blog' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/blog")}>Blog</NavLink>

                    <NavLink to={'/contact'} className={`text-grayTwo cursor-pointer py-2 border-b md:py-0 md:border-b-0 font-normal text-base leading-5 ${isActive === '/contact' ? ' text-primary' : 'text-grayTwo'}`} onClick={() => handleOptionClick("/contact")}>Contact Us</NavLink>
                </NavbarCollapse>
            </Navbar>
        </div>
    )
}

export default NavbarOne