import React from "react";
import Layout from "../Components/Layout/Layout";
import {
  ParagraphMediumLargeBlack,
  Paragraph,
  ParagraphBlackBlog,
} from "../Components/Paragraph";
import { ButtonWhiteBg } from "../Components/Button";

const Blog = () => {
  return (
    <Layout>
      <main className="w-full h-full ">
        <div className="w-full h-[769px] bg-lightgray px-3 py-10 md:px-20 md:py-24">
          <div className="lg:h-96">
            <img
              src="/images/blog-people.png"
              alt="people"
              className="w-full h-full rounded-3xl"
            />
          </div>
          <div className="w-full h-36 flex flex-col lg:flex-row justify-between mt-5 md:mt-10">
            <div className="w-full lg:w-6/12 ">
              <ParagraphMediumLargeBlack
                content={`From Startup to Success: Expert Tips and Resources for
              Entrepreneurs`}
              />
            </div>
            <div className="w-full lg:w-4/12 h-full flex flex-col bottom-0 font-manrope mt-5 md:mt-14">
              <div className="w-full  flex text-left text-grayFour leading-10 ">
                <p className="w-5/12">By</p>
                <p className="w-7/12">Ayobola Dosumu</p>
              </div>
              <div className="w-full  flex text-left text-grayFour leading-10 ">
                <p className="w-5/12">Date issued</p>
                <p className="w-7/12">Nov 10 2023</p>
              </div>
              <div className="w-full  flex text-left text-grayFour leading-10">
                <p className="w-5/12">Categories</p>
                <div className="w-7/12 flex justify-between">
                  <div className="w-5/12">
                    <ButtonWhiteBg type="text" name="START UP" />
                  </div>
                  <div className="w-6/12 outline-none">
                    <ButtonWhiteBg type="text" name="ENTREPRENEURS" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* blog section */}
        <div className="w-full h-full flex flex-col md:flex-row justify-between px-3 py-0 md:px-20 md:py-14">
          <div className="w-full md:w-7/12">
            <img
              src="images/plane.jpg"
              alt="plane"
              className="w-full rounded-xl "
            />
            <div>
              <Paragraph content="Case Study: AeroDrones Logistics in the Transportation Industry" />
              <div className="mt-6">
                <Paragraph content="Innovation: Autonomous Drone Delivery" />
              </div>
              <div className="mt-6">
                <Paragraph content="Process" />
              </div>
              <div className="mt-6">
                <Paragraph content="Technological Feasibility Assessment: Heave Ventures and AeroDrones Logistics initiated a thorough assessment of the technological feasibility of autonomous drone delivery. This involved evaluating advancements in drone technology, navigation systems, obstacle avoidance, and battery life." />
              </div>
            </div>
            <p className="text-deepred font-manrope text-base cursor-pointer">
              Read More
            </p>

            <div className="mt-20">
              <img
                src="images/village.jpg"
                alt="farm"
                className="w-full rounded-xl md:h-[362px] "
              />

              <div>
                <Paragraph content="Case Study SolarTech Solutions in the Energy Industry" />
                <div className="mt-6">
                  <Paragraph content="Innovation: Advanced Solar Panel Technology" />
                </div>
                <div className="mt-6">
                  <Paragraph content="Process" />
                </div>
                <div className="mt-6">
                  <Paragraph content="Research and Development (R&D): SolarTech Solutions invested heavily in research and development to explore novel materials and manufacturing processes for solar panels. The company collaborated with Heave Ventures to research materials science, engineering, and photovoltaics for the purpose of identifying breakthrough technologies." />
                </div>
              </div>
              <p className="text-deepred font-manrope text-base cursor-pointer">
                Read More
              </p>
            </div>
          </div>
          <div className="w-full md:w-4/12">
            <p className="font-lexend text-2xl text-blackTwo leading-8">
              The Latest
            </p>
            <div className="border-b-[1px] border-grayFive w-full mt-10"></div>
            <div>
              <ParagraphBlackBlog content="Startup journey insights for female entrepreneurs" />
              <p className=" font-manrope mt-9 text-graySix">6 min read</p>
              <div className="border-b-[1px] border-grayFive w-full mt-2"></div>
            </div>
            <div>
              <ParagraphBlackBlog content="Top 10 Innovation consulting firms in Africa" />
              <p className=" font-manrope mt-9 text-graySix">3 min read</p>
              <div className="border-b-[1px] border-grayFive w-full mt-2"></div>
            </div>
            <div>
              <ParagraphBlackBlog content="The Power of Networking for Business Success" />
              <p className=" font-manrope mt-9 text-graySix">7 min read</p>
              <div className="border-b-[1px] border-grayFive w-full mt-2"></div>
            </div>
            <div>
              <ParagraphBlackBlog content="Financial Planning and Budgeting for Small Businesses" />
              <p className=" font-manrope mt-9 text-graySix">10 min read</p>
              <div className="border-b-[1px] border-grayFive w-full mt-2"></div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Blog;
