import React from 'react'
import Footer from './Footer'
import NavbarOne from './Navbar'

const Layout = ({children}) => {
    return (
        <main>
            <NavbarOne/>
            <main>{children}</main>
            <Footer />
        </main>
    )
}

export default Layout