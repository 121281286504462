import React from 'react'
import { LuFlaskRound } from "react-icons/lu";
import { TbTargetArrow } from "react-icons/tb";
import Layout from '../Components/Layout/Layout'
import { ButtonPrimary, ButtonPrimaryOrange, ButtonWhiteBg } from '../Components/Button'
import { ParagraphBlackBigLead, ParagraphBlackThin, ParagraphExtraLarge, ParagraphExtraXLargeBlack } from '../Components/Paragraph';
import InnovationAccordion from '../Components/Cards/InnovationAccordion';
import CallNo from '../Components/CallNo';

const Innovation = () => {
    return (
        <Layout>
            <main>
                <div className="flex flex-col-reverse lg:flex-row justify-between px-5 md:px-20 py-14">
                    <div className="w-full mt-5 lg:mt-0 lg:w-5/12">
                        <div className=" relative w-11/12">
                            <ButtonPrimaryOrange name={'UNLOCK YOUR INNOVATION POTENTIAL'} />
                            <LuFlaskRound className='absolute top-2 left-4 h-6 w-6 text-white' />
                        </div>
                        <ParagraphExtraLarge content={'HeaveDiscovery© and HeaveSource©'} />
                        <ParagraphBlackThin content={'Top organizations globally use Heave AI-toolkit to enhance their innovation performance significantly.'} />
                        <div className=" my-10 relative">
                            <input type="text" name="" id="" className=' border-blackTwo bg-white text-grayFive rounded-full ring-transparent ring-1 focus:border-blackTwo focus:ring-transparent px-3 h-12 w-full' placeholder='Email Address' />
                            <div className=" w-36 h-8 absolute right-1 top-1">
                                <ButtonPrimary name={'Request Demo'} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-5/12">
                        <div className=" h-80">
                            <img src="Images/inno.jpeg" alt="" className=' rounded-2xl h-full' />
                        </div>
                    </div>
                </div>
                <div className="bg-OrangeLight flex flex-col lg:flex-row justify-around items-center py-20 px-10 mt-10">
                    <div className="w-full lg:w-[45%]">
                        <ParagraphExtraXLargeBlack content={"What the toolbox offer?"} />
                        <ParagraphBlackThin content={'We have spent the last 3 decades experiencing what it takes to grow Innovative businesses.'} />
                    </div>
                    <div className="w-full lg:w-[45%]">
                        <InnovationAccordion />
                    </div>
                </div>
                <div className="py-20 px-10 mt-10">
                    <div className="flex flex-col lg:flex-row bg-grayEigth rounded-3xl">
                        <div className="p-5 md:p-20">
                            <div className="w-40 mb-10 relative">
                                <ButtonWhiteBg name={'CONTACT US'} />
                                <div className=" bg-deepred rounded-full absolute top-0 left h-8 w-8 p-2">
                                    <TbTargetArrow className='text-white' />
                                </div>
                            </div>
                            <ParagraphBlackBigLead content={'Speak to an Expert to check your Innovation health.'} />
                            <div className=" w-36 mt-20">
                                {/* <ButtonPrimary name={'Call No'} /> */}
                                <CallNo />
                            </div>
                        </div>
                        <div className="p-5 md:p-0">
                            <img src="Images/innnovation.jpg" alt="" srcset="" className='w-full rounded-3xl' />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Innovation